<template>
  <div>
    <header-category
      :title="title"
      :subtitle="subtitle"
      :icon="icon"
      class="mx-0 xs:mx-8 md:mx-18 lg:mx-0"
      icon-color="bg-orange"
      shareable
    ></header-category>

    <div class="px-8 mb-15 md:mb-20 md:px-18 md:pb-18 bg-white rounded-b-4xl content-container">
      <figure v-if="image" class="mb-8">
        <picture class="block overflow-hidden mb-8">
          <img class="block picture rounded-t-2xl overflow-hidden mx-auto" :src="image" fit="cover" />
        </picture>
        <figcaption v-if="caption" class="text-xs text-center mt-2">
          {{ caption }}
        </figcaption>
      </figure>
      <section-wysiwyg v-for="part in body_parts" :key="part.id" :content="part"></section-wysiwyg>
      <p class="w-full flex justify-end text-sm">
        <button-back-top></button-back-top>
      </p>
      <section-share-social></section-share-social>
    </div>
  </div>
</template>

<script>
import ButtonBackTop from "~/components/Buttons/ButtonBackTop.vue";
import HeaderCategory from "~/components/Headers/HeaderCategory.vue";
import SectionShareSocial from "~/components/Sections/SectionShareSocial.vue";
import SectionWysiwyg from "~/components/Sections/SectionWysiwyg.vue";


import query from "~/queries/post";

import { setupMeta } from "~/utils/utils";

export default {
  name: "PagePreventionCategoryArticle",
  scrollToTop: true,
  async setup() {
    defineI18nRoute({
      locales: ["fr"],
      paths: {
        fr: "/je-previens/[category]/[post]",
      },
    });
    const { locale } = useI18n();
    const route = useRoute();
    const nuxtApp = useNuxtApp();
    
    const {
      data: {
        _value: { article },
      },
    } = await nuxtApp.runWithContext(() => useAsyncQuery(query, {
      siteId: locale.value === "fr" ? 1 : 2,
      slug: route.params.post,
    }));

    const image = article.image && article.image.length > 0 ? article.image[0].url : false;
    
    await nuxtApp.runWithContext(() => setupMeta(image, {
      title: article.seoArticle.title,
      description: article.seoArticle.description,
    }));

    return {
      title: article.articleTitle,
      subtitle: article.articleSubtitle,
      icon: article.icon && article.icon.length > 0 ? article.icon[0].url : "",
      image,
      caption: article.caption,
      body_parts: article.bodyCopy,
    };
  },
  components: {
    HeaderCategory,
    SectionWysiwyg,
    SectionShareSocial,
    ButtonBackTop,
  },
};
</script>

<style lang="scss">
.content-container {
  h3 {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 30px;
  }
}
</style>
